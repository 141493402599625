/* You can add global styles to this file, and also import other style files */


$background_white: #FAFAFA;
$smoked_white: #F1F1F1;
$less_than_more_smoked_white: #e5e5e5;
$more_smoked_white: #cccccc;
$black: #000;
$border_radius: 0px;

$temp_color: grey;
$ordered_color: blue;
$active_color: purple;
$fab_color: orange;
$awaitingPickup_color: yellow;
$transp_color: rgb(2, 255, 2);
$delivered_color: green;
$err_color: red;
$returned_color: rgb(122, 0, 0);
$done_color: rgb(95, 95, 95);


// System wide css
html, body {
  height: 100% !important;
  background-color: $background_white !important;
}




// Boostrap overrides
.card {
  background-color: $smoked_white !important;
  border-radius: $border_radius !important;
}

.btn {
  border-radius: $border_radius;
}

.form-control {
  border-radius: $border_radius;
}

input {
  border-radius: $border_radius;
}


.info-buttom-elementar {
  background-color: $black;
  border-color: $black;
}

.info-buttom-elementar:hover {
  background-color: $more_smoked_white;
  border-color: $more_smoked_white;
  color: $black;
}

// COLOR MACROS
.temporary-bg {
  background-color: $temp_color;
}

.temporary-color {
  color: $temp_color;
}

.ordered-bg {
  background-color: $ordered_color;
}

.ordered-color {
  color: $ordered_color;
}

.active-bg {
  background-color: $active_color;
}

.active-color {
  color: $active_color;
}

.fabricating-bg {
  background-color: $fab_color;
}

.fabricating-color {
  color: $fab_color;
}

.awaitingPickup-bg {
  background-color: $awaitingPickup_color;
}

.awaitingPickup-color {
  color: $awaitingPickup_color;
}

.transportation-bg {
  background-color: $transp_color;
}

.transportation-color {
  color: $transp_color;
}

.delivered-bg {
  background-color: $delivered_color;
}

.delivered-color {
  color: $delivered_color;
}

.error-bg {
  background-color: $err_color;
}

.error-color {
  color: $err_color;
}

.returned-bg {
  background-color: $returned_color;
}

.returned-color {
  color: $returned_color;
}

.done-bg {
  background-color: $done_color;
}

.done-color {
  color: $done_color;
}


// CUSTOM FOTONS ELEMENTS
.custom-card-wrapper {
  height: 150px;
  display: flex;
  flex-direction: column;
  padding: 10px;

  .custom-card-title {
      font-size: 25px;
      text-align: end;
  }

  .custom-card-value {
      font-size: 40px;
      flex: 1 !important;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
  }

  .custom-card-icon {
      font-size: 100px;
      color: #e5e5e5;
      margin-top: -100px;
      height: 100px;
      display: flex;
      flex-direction: column;
      padding-left: 10px;
  }
}

// GENERAL CSS
.forbidden {
  cursor: not-allowed !important;
  color: #c4c4c4;
}

.btn-elementar {
  background-color: black;
  border-color: black !important;
  color: white;
  border-radius: 0px;
  padding-left: 20px;
  padding-right: 20px;
  height: 36px;
}

.btn-elementar:hover {
  background-color: #464646 !important;
}







.content-padding {
  padding: 10px;
}



.flex {
  display: flex !important;
}

.flex-column {
  flex-direction: column !important;
}


elmtr-authenticated, elmtr-public {
  width: 100%;
}

.height-100 {
  height: 100% !important;
}

.user-card {
  width: 200px;
  display: flex;
  flex-direction: row;
  .user-image {
    height: 50px;
    width: 50px;
    img {
      height: 100%;
    }
  }
  .user-content-wrapper {
    margin-left: 5px;
    display: flex;
    flex-direction: column;
    .name {
      font-size: 20px;
    }
    .extra-info {
      color: $more_smoked_white;
      font-size: 15px;
    }
  }
}

// elmtr-login {
//   height: 100% !important;
//   width: 100%;
//   display: flex;
// }
